import { combineReducers } from "redux";

import uiReducer, { uiInitialState } from "./ui/slice";
import authUserReducer, { authUserInitialState } from "./auth-user/slice";
import userApprovalReducer, { userApprovalInitialState } from "./user-approval/slice";
import feedbacKReducer, { feedbackInitialState } from "./feedback/slice";
import fileFolderUploadReducer, { fileFolderUploadInitialState } from "./file-folder-upload/slice";
import documentReducer, { documentInitialState } from './document/slice'
import exportSummaryReducer, { exportSummaryInitialState } from './export-summary/slice'
import questionnaireReducer , { questionnaireInitialState } from "./questionnaire/slice";
export const initialState = {
  ui: uiInitialState,
  authUser: authUserInitialState,
  userApproval: userApprovalInitialState,
  feedback: feedbackInitialState,
  fileFolderUpload: fileFolderUploadInitialState,
  document: documentInitialState,
  exportSummary: exportSummaryInitialState,
  questionnaire : questionnaireInitialState
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    authUser: authUserReducer,
    userApproval: userApprovalReducer,
    feedback: feedbacKReducer,
    fileFolderUpload: fileFolderUploadReducer,
    document: documentReducer,
    exportSummary: exportSummaryReducer,
    questionnaire: questionnaireReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
