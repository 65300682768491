import React, { useState } from "react";
import "./response-letter.scss";
import { Button, Dropdown, Menu, Flex } from "antd";
import RadioToggle from "../../components/radio-toggle/RadioToggle";
import { responseLetterValues } from "./constants";
import { RadioChangeEvent } from "antd/lib";
import { useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import Markdown from "react-markdown";
import { priorAuthService } from "../../api";
import { openNotificationWithIcon } from "../../utils";

const ResponseLetter = ({ fileName, documentUUID }: { fileName: string; documentUUID: string }) => {
  const [visible, setVisible] = useState(false);
  const [elaboration, setElaboration] = useState<string>("short");
  const [education, setEducation] = useState<string>("college");
  const [responseLetterContent, setResponseLetterContent] = useState<string>((responseLetterValues as any)[fileName]?.shortCollege);
  const navigate = useNavigate();

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const handleElaborationChange = (e: RadioChangeEvent) => {
    setElaboration(e.target.value);
  };

  const handleEducationChange = (e: RadioChangeEvent) => {
    setEducation(e.target.value);
  };

  const getSubmitReview = async (status: string) => {
    try {
      await priorAuthService.submitReview({ status }, documentUUID);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const generateResponseLetter = () => {
    let letter = "";
    if (elaboration === "short" && education === "college")
      letter = (responseLetterValues as any)[fileName]?.shortCollege;
    else if (elaboration === "elaborate" && education === "college")
      letter = (responseLetterValues as any)[fileName]?.elaborateCollege;
    else if (elaboration === "elaborate" && education === "middleSchool")
      letter = (responseLetterValues as any)[fileName]?.elaborateMiddleSchool;
    else if (elaboration === "short" && education === "middleSchool")
      letter = (responseLetterValues as any)[fileName]?.shortMiddleSchool;
    setResponseLetterContent(letter);
  };

  const handleApproveButton = async () => {
    await getSubmitReview("APPROVED");
    navigate(-1);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" className="flex gp">
        <Flex vertical gap="10px" onClick={(e) => e.stopPropagation()}>
          <Flex vertical gap="10px">
            <h3>Form</h3>
            <RadioToggle
              options={[
                { label: "Short", value: "short" },
                { label: "Elaborate", value: "elaborate" },
              ]}
              value={elaboration}
              onChange={handleElaborationChange}
            />
          </Flex>
          <Flex vertical gap="10px">
            <h3>Grade</h3>
            <RadioToggle
              options={[
                { label: "College", value: "college" },
                { label: "Middle School", value: "middleSchool" },
              ]}
              value={education}
              onChange={handleEducationChange}
            />
          </Flex>
          <Button className="fill" onClick={generateResponseLetter}>
            Regenerate
          </Button>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="response-letter-container">
        <Flex justify="space-between">
          <h3>Personalized Letter</h3>
          <Dropdown
            overlay={menu}
            open={visible}
            onOpenChange={handleVisibleChange}
            trigger={["click"]}
          >
            <Button className="outline">Settings</Button>
          </Dropdown>
        </Flex>
        {/* <pre style={{ fontFamily: `Arial, Sans-Serif`, whiteSpace: "pre-wrap" }}>
          <ReactTyped
            strings={[getResponseLetter()]}
            typeSpeed={1}
            showCursor={false}
            style={{ fontSize: "16px" }}
          />
        </pre> */}
        <Markdown>
          {responseLetterContent}
        </Markdown>
      </div>
      <div className="approve">
        <Button className="fill" onClick={handleApproveButton}>
          Forward Letter
        </Button>
      </div>
    </>
  );
};

export default ResponseLetter;