import React, { useEffect, useState } from "react";
import { Radio, Button, Card, Space} from "antd";
import { openNotificationWithIcon } from "../../utils";
import { patientSummaryService } from "../../api";
import { RootState, setQuestionnaireByDocId } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../components";

interface ResponseType {
  answer: string;
  next_question: string; 
}

export interface QuestionnaireItem {
  id: number;
  questionId: number;
  projectId: number;
  question: string;
  responseTypes: ResponseType[];
  responseSelected: string | null;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface QuestionnaireProps {
  questionnaire: QuestionnaireItem[];
  setQuestionnaireRequest: React.Dispatch<React.SetStateAction<QuestionnaireItem[]>>;
  questionnaireByDocId:any
}

const Questionnaire : React.FC<QuestionnaireProps> = ({ 
  questionnaire, setQuestionnaireRequest,questionnaireByDocId,
}) => {
  const [tempAnswers, setTempAnswers] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { documentId} = useSelector((state: RootState) => state.document);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const matchedQuestions = questionnaireByDocId
      .map((docItem: any) => {
        const matchedQuestion = questionnaire.find(
          (q) => q.id === docItem.questionnaireFk
        );
        if (matchedQuestion) {
          return {
            ...matchedQuestion,
            responseSelected: docItem.responseSelected,
          };
        }
        return null; 
      })
      .filter(Boolean) as QuestionnaireItem[]; 
      setTempAnswers(matchedQuestions);
  }, [questionnaire, questionnaireByDocId]);

  const findQuestionByQuestionId = (questionId: number) => {
    return questionnaire.find((q: any) => q.id === questionId);
  };
  const findNextQuestionByQuestionId = (questionId: number) => {
    return questionnaire.find((q: any) => q.questionId === questionId);
  };

  const handleEdit = () => {
      setIsEditMode(true);
  };

  const handleSave = async() => {
    const updateData = tempAnswers.map((item) => ({
      questionnaireFk: item.id,
      responseSelected: item.responseSelected
    }));
    const generateSummaryRequest = tempAnswers.map(item => ({
      ...item,
      responseTypes: item.responseTypes.map((rt:any) => 
        rt.next_question === '-1' ? 
        { ...rt, next_question: 'D' } : 
        rt
      )
    }));
    const documentbydocId = tempAnswers.map((item) => ({
      id: item.id,
      questionnaireFk: item.id,
      responseSelected: item.responseSelected,
      documentId:documentId,
      isDeleted: item.isDeleted,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt
    }));
    setQuestionnaireRequest(generateSummaryRequest);

  
    dispatch(setQuestionnaireByDocId(documentbydocId));
    setIsLoading(true);
    try {
      await patientSummaryService.updateQuestionnaireResponse(documentId,updateData);
      openNotificationWithIcon("", "Questionnaire updated successfully", "success");
      setTempAnswers(generateSummaryRequest);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }finally {
      setIsLoading(false);
      setIsEditMode(false);
    }
  };

  const handleAnswerChange = (answer: string, ind: number, id: number) => {
    const currentQuestion = findQuestionByQuestionId(id);
  
    if (!currentQuestion) {
      console.error("Question not found.");
      return;
    }
      const updatedTempAnswers = tempAnswers.slice(0, ind + 1).map((item, index) =>
        index === ind ? { ...item, responseSelected: answer } : item
      );
  
      const nextQuesId = currentQuestion.responseTypes.find(
        (rt: ResponseType) => rt.answer === answer
      )?.next_question;
  
  
      if (!nextQuesId || nextQuesId === "-1") {
        setTempAnswers(updatedTempAnswers); 
        return;
      }
  
      const nextQuestion = findNextQuestionByQuestionId(Number(nextQuesId));
  
      if (nextQuestion) {
        setTempAnswers([...updatedTempAnswers, nextQuestion]);
      }
    
  };
  
  

  return (
    <div style={{ maxWidth: 800, margin: '0 auto', padding: 24 }}>
      <h2 className="patientData-info">QA Responses:</h2>
      {isEditMode ? (
         <Space direction="vertical" size={16} style={{ width: '100%' }}>
         {tempAnswers.map((q: any,ind:number) => (
           <Card key={q.id}>
             <h5 style={{padding:"5px", fontFamily:"Helvetica Neue"}}>
               {q.question}
             </h5>
             <Radio.Group
               value={q.responseSelected}
               onChange={(e) => handleAnswerChange(e.target.value,ind,q.id)}
             >
               <Space direction="vertical">
                 {q.responseTypes.map((option: any) => (
                   <Radio key={option.answer} value={option.answer}>
                     {option.answer}
                   </Radio>
                 ))}
               </Space>
             </Radio.Group>
           </Card>
         ))}
       </Space>
       
      ) : 
        (<Space direction="vertical" size={16} style={{ width: '100%' }}>
          {tempAnswers.map((q: any) => (
            <Card key={q.id}>
              <h5 style={{padding:"5px", fontFamily:"Helvetica Neue"}}>
                {q.question}
              </h5>
              <Radio.Group
                value={q.responseSelected}
                style={{padding:"5px", pointerEvents:"none"}}
              >
                <Space direction="vertical">
                  {q.responseTypes.map((option: any) => (
                    <Radio key={option.answer} value={option.answer}>
                      {option.answer}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Card>
          ))}
        </Space>)
      }
      <div style={{ marginTop: 24 }}>
        {!isEditMode &&(
          <Button className="fill" onClick={handleEdit} disabled={isEditMode}>
            Edit
          </Button>
        )}
        {
          isEditMode && (isLoading ? <Loading/> : <Button className="fill" onClick={handleSave}>Save</Button>)
        }
      </div>
    </div>
  );
};

export default Questionnaire;
