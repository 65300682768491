import React, { ReactNode, useEffect, useState } from "react";
import "./patient-summary.scss";
import { Button, Collapse, Flex, Dropdown, Menu, Modal } from "antd";
import {
  CopyOutlined,
  DislikeOutlined,
  DownCircleOutlined,
  DownloadOutlined,
  LikeOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { DiagAndProcCodes, patientSummaryService, uploadFileFolderService } from "../../api";
import { ReactTyped } from "react-typed";
import {
  handleCopyToClipboard,
  handleDocxDownload,
  handlePdfDownload,
  MODAL_TYPE,
  openNotificationWithIcon,
} from "../../utils";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CodesPopup } from "./CodesPopup";
import {
  extractValues,
  extractValuesSummary,
  nestedValues,
  shortFormSummary,
} from "../case-details-container/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  RootState,
  setFeedbackType,
  setPatientSources,
  setPatientsSummary,
  setQuestionnaire,
  setQuestionnaireByDocId,
} from "../../store";
import RadioToggle from "../../components/radio-toggle/RadioToggle";
import { RadioChangeEvent } from "antd/lib";
import Questionnaire, { QuestionnaireItem } from "../questionnaire/Questionnaire";
import Markdown from "react-markdown";
import { ISources } from "../guideline-compliance";
import {
  setCurrentChunkUUID,
  setCurrentPage as setGlobalCurrentPage,
} from "../../store/document/slice";
import { SourcePopup } from "../modals/source-popup/SourcePopup";

export type ProcedureProps = {
  procedureCode?: string;
  procedureDescription?: string;
};

export type DiagnosisProps = {
  diagnosisCode?: string;
  diagnosisDescription?: string;
};

type Props = {
  enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
  documentUUID: string;
  projectId: number;
  documentName: string;
  setLastQuestion?: any;
};

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  procedureDescription: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
  diagnosisDescription: string;
}

interface PatientData {
  acuity: string;
  admissionDateTime: string;
  authorizationID: string;
  dateOfBirth: string;
  dischargeDateTime: string;
  memberID: string;
  memberName: string;
  placeOfService: string;
  requestingProviderName: string;
  requestingProviderFaxNo: string;
  requestingProviderNPI: string;
  servicingProviderName: string;
  servicingProviderNPI: string;
  typeOfService: string;
  state: string;
  procedures: Procedure[];
  diagnoses: Diagnosis[];
  [key: string]: string | any;
}

const documentsWithHardCodedValues = [
  "PBMCase1.pdf",
  "PBMCase2.pdf",
  "PBMCase3.pdf",
  "PBMCase4.pdf",
  "1ccb3072-cfdd-4898-a082-c891e27d01f0.pdf",
];

const UUID_REGEX = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;

export const PatientSummaryNew = ({
  enableNextTab,
  documentUUID,
  projectId,
  documentName,
  setLastQuestion
}: Props) => {
  const [showPatientSummary, setShowPatientSummary] = useState(false);
  const [expandPatientData, setExpandPatientData] = useState(true);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [selectedProcedureCode, setSelectedProcedureCode] = useState<ProcedureProps>({
    procedureCode: "",
    procedureDescription: "",
  });
  const [selectedDiagnosisCode, setSelectedDiagnosisCode] = useState<DiagnosisProps>({
    diagnosisCode: "",
    diagnosisDescription: "",
  });
  const [patientSummary, setPatientSummary] = useState<any>();
  const [shortPatientSummary, setShortPatientSummary] = useState("");
  const [shortSummary, setShortSummary] = useState(``);
  const [caseInfo, setCaseInfo] = useState(extractValues[documentName]);
  const [diagAndProcCodes, setDiagAndProcCodes] = useState([] as DiagAndProcCodes[]);
  const [timeToProcess, setTimeToProcess] = useState<number>(0);
  const [extractedFieldCount, setExtractedFieldCount] = useState<number>(0);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState<string>("long");
  const { questionnaire, questionnaireByDocId } = useSelector(
    (state: RootState) => state.questionnaire
  );
  const [questionnaireRequest, setQuestionnaireRequest] = useState<any[]>(questionnaire);
  const { projectDetails } = useSelector((state: RootState) => state.document);
  const [isLoading, setIsLoading] = useState(false);
  const { documentId } = useSelector((state: RootState) => state.document);
  const [fileUrl, setFileUrl] = useState("");
  const [chatSource, setChatSource] = useState({} as ISources);
  const [sources, setSources] = useState<ISources[]>([]);

  const dispatch = useDispatch();
  const getCaseInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await patientSummaryService.getCaseInfo(documentUUID);
      setDiagAndProcCodes(data.data.diagAndProcCodes);
      setTimeToProcess(data.data.timeToProcess);
      setExtractedFieldCount(data.data.extractedFieldCount);
      if (projectDetails.projectName === "Pharmacy Auths") {
        const result = await patientSummaryService.getQuestionnaire();
        dispatch(setQuestionnaire(result.data.data));
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const questionnaireData = async () => {
    setIsLoading(true);
    if (projectDetails.projectName === "Pharmacy Auths" && documentId != 0) {
      const { data } = await patientSummaryService.getQuestionnaireResponse(documentId);
      dispatch(setQuestionnaireByDocId(data));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCaseInfo();
    questionnaireData();
  }, []);

  const transformQuestionnaireFormat = (originalQuestionnaire: any) => {
    return originalQuestionnaire.map((item: any) => {
      const matchedResponseType = item.responseTypes.find(
        (rt: any) => rt.answer === item.responseSelected
      );
      return {
        questionId: item.questionId,
        question: item.question,
        responseSelected: item.responseSelected || "",
        nextQuestion: matchedResponseType ? matchedResponseType.next_question : "",
      };
    });
  };

  const handleGeneratePatientSummary = async () => {
    setIsSummaryLoading(true);
    setIsLoading(true);
    try {
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        selectedDiagnosisCode,
        selectedProcedureCode
      );
      setPatientSummary(data.data.summary || data.data.message || "Summary not found");
      setShortPatientSummary(data.data.shortFormSummary || "Short Summary not generated");
      setSources(data?.data?.sources);
      setShowPatientSummary(true);
      setExpandPatientData(false);
      setIsSummaryLoading(false);
    } catch (error) {
      openNotificationWithIcon("", "Error Generating Patient Summary", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateCompliance = () => {
    enableNextTab(selectedProcedureCode, selectedDiagnosisCode);
  };

  const formatKey = (key: string) => {
    const words = key.split(/(?=[A-Z])/);
    const formattedKey = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  const handleThumbsClick = (type: "up" | "down") => {
    // dispatch(
    //   setQuestionAnswerLogId({
    //     logId: item.logId,
    //     qaId: item.qaId,
    //   })
    // );
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  }
  const handleGenerateQuestionnaireSummary = async () => {
    try {
      setIsLoading(true);
      const matchedQuestions = questionnaireByDocId.map((docItem: any) => {
        const matchedQuestion = questionnaire.find(
          (q) => q.id === docItem.questionnaireFk
        );
        if (matchedQuestion) {
          return {
            ...matchedQuestion,
            responseSelected: docItem.responseSelected,
          };
        }
        return null; 
      })
      const transformedQuestionnaire = transformQuestionnaireFormat(matchedQuestions).map((item: any) => ({
        ...item,
        nextQuestion: item.nextQuestion === '-1' ? 'D' : item.nextQuestion,
      }));
      const payload =
        transformedQuestionnaire.length == 0
          ? transformQuestionnaireFormat(questionnaire)
          : transformedQuestionnaire;
      const n=payload.length;
      setLastQuestion(payload[n-1].question);
      const { data } = await patientSummaryService.generateQuestionnaireSummary(
        documentUUID,
        payload
      );
      dispatch(setPatientsSummary(data.data.summary || data.data.message || "Summary not found"));
      dispatch(setPatientSources(data.data.sources));
      enableNextTab(selectedProcedureCode, selectedDiagnosisCode);
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const exportMenu = (
    <Menu>
      <Menu.Item
        key="pdf"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handlePdfDownload(patientSummary, "Patient Summary", documentName)}
      >
        Download as PDF
      </Menu.Item>
      <Menu.Item
        key="docx"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handleDocxDownload(patientSummary, "Patient Summary", documentName)}
      >
        Download as Docx
      </Menu.Item>
      <Menu.Item
        key="copy"
        icon={<CopyOutlined />}
        className="export-dropdown-item"
        onClick={() => handleCopyToClipboard(patientSummary, "Patient Summary")}
      >
        Copy to Clipboard
      </Menu.Item>
    </Menu>
  );

  const radioOptions = [
    { label: "Long Form", value: "long" },
    { label: "Short Form", value: "short" },
  ];

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedForm(e.target.value);
  };

  const renderValue = (value: any) => {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return (
        <div className="nested-object">
          {Object.keys(value).map((nestedKey) => (
            <div className="nested-item" key={nestedKey}>
              <span className="nested-key">{nestedKey}:</span>
              <span className="nested-value">{value[nestedKey]}</span>
            </div>
          ))}
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div className="nested-array">
          {value.map((item, index) => (
            <div key={index} className="nested-array-item">
              {Object.keys(item).map((nestedKey) => (
                <div key={nestedKey}>
                  <span className="nested-key">{`${nestedKey}: `}</span>
                  <span className="nested-value">{item[nestedKey]}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return <span>{value}</span>;
  };

  const renderNestedValues = () => {
    const data = nestedValues[documentName];

    if (!data) {
      return null;
    }

    return (
      <div className="single-info grid grid-cols-2">
        {Object.keys(data).map((key) => (
          <React.Fragment key={key}>
            <div className="patientData-info">{key}</div>
            <div className="fz-16 p-block">{renderValue(data[key])}</div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const handleSourceClick = async (source: ISources, pageNumber?: number, chunkUUID?: string) => {
    setFileUrl("");
    setChatSource(source);
    dispatch(setGlobalCurrentPage(pageNumber || 1));
    dispatch(setCurrentChunkUUID(chunkUUID || ""));
    try {
      const { data } = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
      setFileUrl(data.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const renderMedicalHistorySources = (): ReactNode => {
    return (
      <div className="source-container">
        <h5>Sources: </h5>
        <div className="source-list flex gp-10">
          {!!sources?.length &&
            sources?.map((source, i) => {
              return (
                <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                  <a>{source?.category}[1]</a>
                </li>
              );
            })}
        </div>
      </div>
    );
  };

  const getSourceNumberMap = (sourcesNew: ISources[]) => {
    const map: {
      [key: string]: { source: ISources; sourceNumber: number; pageNo: number; chunkUUID: string };
    } = {};
    sourcesNew.forEach((source, index) => {
      source.pages.forEach((page) => {
        map[page.chunkUUID] = {
          source,
          sourceNumber: index + 1,
          pageNo: page.pageNo,
          chunkUUID: page.chunkUUID,
        };
      });
    });
    return map;
  };

  const extractTextRecursively = (node: any): string => {
    if (typeof node === "string") {
      return node;
    }
    if (Array.isArray(node)) {
      return node.map(extractTextRecursively).join("");
    }
    if (node?.props?.children) {
      return extractTextRecursively(node.props.children);
    }
    return "";
  };

  

  const renderAnswer = (patientSummaryNew: string, source: ISources[]): ReactNode => {
    const renderCitation = (currentLine: any) => {
      if (typeof currentLine !== "string" && typeof currentLine !== "object") {
        currentLine = extractTextRecursively(currentLine);
      } else if (typeof currentLine === "object") {
        if (Array.isArray(currentLine)) {
          currentLine = currentLine.map(extractTextRecursively).join("");
        } else if (currentLine?.props?.children) {
          currentLine = extractTextRecursively(currentLine.props.children);
        }
      }
      const sourceNumberMap = getSourceNumberMap(source);

      const parts = currentLine?.split(UUID_REGEX);
      const chunkUUIDs = currentLine.match(UUID_REGEX) || [];

      const processedParts = parts.map((part: any, index: any) => {
        const uuid = chunkUUIDs[index];
        const citation =
          uuid && sourceNumberMap[uuid] ? `${sourceNumberMap[uuid].sourceNumber}` : "";

        return (
          <React.Fragment key={index}>
            <span>{part}</span>
            {citation && (
              <span
                className="citation-number"
                onClick={() =>
                  handleSourceClick(
                    sourceNumberMap[uuid].source,
                    sourceNumberMap[uuid].pageNo,
                    sourceNumberMap[uuid].chunkUUID
                  )
                }
              >
                {citation}
              </span>
            )}
          </React.Fragment>
        );
      });

      return <>{processedParts}</>;
    };

    const components = {
      li(props: any) {
        const { children, className, node, ...rest } = props;
        return <li>{renderCitation(children)}</li>;
      },
      p(props: any) {
        const { children, className, node, ...rest } = props;
        return <p>{renderCitation(children)}</p>;
      },
    };
    return (
      <Markdown className="citation-markdown" components={components}>
        {patientSummaryNew}
      </Markdown>
    );
  };

  return (
    <>
      <div className="patientData">
        <div className="patientData-info">
          {`Time to process: ${(
            timeToProcess / 1000
          ).toFixed()} secs  |  Extracted fields count:  ${
            extractedFieldCount == 0 && caseInfo
              ? Object.keys(caseInfo).length
              : extractedFieldCount
          }`}
        </div>
        <div
          className={`patientData-patientInfo  ${
            patientSummary
              ? `patientData-patientInfo-${expandPatientData ? "expanded" : "short"}`
              : "show-full"
          }`}
        >
          {documentName === "Billing Document - 8007307259.pdf" ||
          documentName === "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf" ||
          documentName === "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf" ||
          documentName === "Invoice-2024-83163-00.pdf" ? (
            <div>{renderNestedValues()}</div>
          ) : (
            Object.keys(caseInfo).length > 0 && (
              <div className="single-info grid grid-cols-2">
                <div className="patientData-info">{Object.keys(caseInfo)[0]}</div>
                <div className="fz-16 p-block">
                  {caseInfo[`Member Name`] ||
                    caseInfo[`Date of Birth`] ||
                    caseInfo[`Segment`] ||
                    caseInfo[`Supplier Name`]}
                </div>
              </div>
            )
          )}

          {expandPatientData && (
            <>
              {caseInfo &&
                Object.entries(caseInfo).map(([key, value], index) => {
                  if (key !== Object.keys(caseInfo)[0] && value) {
                    return (
                      <div key={index}>
                        {key !== "Line Item Information" && (
                          <div className="single-info grid grid-cols-2">
                            <div className="patientData-info">{key}</div>
                            <div className="fz-16 p-block">{value}</div>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return null; // Skip rendering if value is empty
                  }
                })}
            </>
          )}
        </div>

        {!expandPatientData ? (
          <div className="patientData-downicon">
            <DownCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        ) : (
          <div className="patientData-upicon">
            <UpCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        )}
        {showPatientSummary ? (
          isSummaryLoading ? (
            <Loading />
          ) : (
            <div>
              <div style={{ marginBottom: "16px" }}>
                <Collapse expandIconPosition="right" defaultActiveKey={["1"]} accordion>
                  <Collapse.Panel
                    header={
                      <h2
                        style={{
                          fontSize: "15px",
                          padding: "2px 8px",
                          margin: 0,
                        }}
                        className="patientData-info"
                      >
                        Patient Summary{" "}
                      </h2>
                    }
                    key="1"
                  >
                    <Flex justify="end" className="p-10">
                      {/* <h2 className="patientData-info">Patient Summary</h2> */}
                      <div className="radio-wrapper">
                        <RadioToggle
                          options={radioOptions}
                          value={selectedForm}
                          onChange={handleRadioChange}
                        />
                      </div>
                    </Flex>
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        fontSize: "16px",
                      }}
                    >
                       {sources?.length == 0 || sources?.length == undefined ? (
                    <ReactTyped
                      strings={[patientSummary.replace(/&/g, "&amp;")]}
                      typeSpeed={1}
                      showCursor={false}
                      style={{ fontSize: "16px" }}
                    />
                  ) : (
                    <>
                      {renderAnswer(
                        selectedForm === "long" ? patientSummary : shortPatientSummary,
                        sources
                      )}
                      {renderMedicalHistorySources()}
                      {!!Object.keys(sources).length && (
                        <SourcePopup
                          chatSource={chatSource}
                          setChatSource={setChatSource}
                          fileUrl={fileUrl}
                          guideline={projectDetails.guidelines}
                        />
                      )}
                    </>
                  )}
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>{" "}
              <div style={{ marginBottom: "16px" }}>
                <Collapse expandIconPosition="right" accordion>
                  <Collapse.Panel
                    header={
                      <h2
                        style={{
                          fontSize: "15px",
                          padding: "2px 8px",
                          margin: 0,
                        }}
                      >
                        Questionnaire{" "}
                      </h2>
                    }
                    key="1"
                  >
                    <Questionnaire
                      setQuestionnaireRequest={setQuestionnaireRequest}
                      questionnaire={questionnaire}
                      questionnaireByDocId={questionnaireByDocId}
                    />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          )
        ) : (
          <div className="patientData-actions">
            <div className="actions">
              <AskAuto projectId={projectId} documentUuid={documentUUID} />
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <Button
                className="fill"
                style={{ float: "right" }}
                onClick={handleGeneratePatientSummary}
                disabled={!extractValuesSummary[documentName]}
              >
                Generate Patient Summary
              </Button>
            )}
          </div>
        )}

        {showPatientSummary && (
          <div
            className="patientData-footer"
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              width: "57.3%",
              height: "7%",
              padding: "35px 20px",
              backgroundColor: "#f8f9fa",
              marginBottom: "6px",
            }}
          >
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
              {/* <LikeOutlined className="cursor-pointer flex action" />
              <DislikeOutlined className="cursor-pointer flex ask-auto-button"/>{" "} */}
            </div>
            <div className="flex gp">
              <Dropdown overlay={exportMenu} placement="topCenter" trigger={["click"]}>
                <Button className="outline">Export Summary as</Button>
              </Dropdown>
              {isLoading ? (
                <Loading />
              ) : (
                <Button className="fill" onClick={handleGenerateQuestionnaireSummary}>
                  Generate Summary
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}


export default PatientSummaryNew;
