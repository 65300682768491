import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { SourcePopup } from "../modals/source-popup/SourcePopup";
import { DiagnosisProps, ProcedureProps } from ".";
import { useDispatch, useSelector } from "react-redux";
import { handleCopyToClipboard, handleDocxDownload, handlePdfDownload, openNotificationWithIcon } from "../../utils";
import { patientSummaryService, uploadFileFolderService } from "../../api";
import { FailureIcon, SuccessIcon } from "../../assets/icons";
import {setCurrentChunkUUID,setCurrentPage as setGlobalCurrentPage,} from "../../store/document/slice";
import { RootState,  } from "../../store";
import { Button, Dropdown, Menu } from "antd";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import Markdown from "react-markdown";
import { ISources } from "../guideline-compliance";

export type ISourcesNew = {
  unique_id: string;
  document_name: string;
  document_id:string;
  page_number: number;
  offsets: { begin: number; end: number };
  page_start: number;
  page_end: number;
  sourceNumber: number;
};

type Props = {
    documentName: string;
    enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
    documentUUID: string;
    projectId: number;
    setFileUrl: React.Dispatch<React.SetStateAction<string>>;
    fileUrl: string;
    procedureCode: any
    diagnosisCode: any
    lastQuestion: string;
};

export const Summary = ({
  enableNextTab,
  projectId,
  setFileUrl,
  documentUUID,
  fileUrl,
  documentName,
  procedureCode,
  diagnosisCode,
  lastQuestion
}: Props) => {

    const dispatch= useDispatch();
    const [chatSource, setChatSource] = useState({} as ISources);
    const { projectDetails } = useSelector((state: RootState) => state.document);
    const { patientSummary,sources } = useSelector((state: RootState) => state.exportSummary);
    const handleSourceClick = async (source: ISources) => {
      setFileUrl("");
      setChatSource(source);
      dispatch(setGlobalCurrentPage(source.pages[0].pageNo || 1));
      dispatch(setCurrentChunkUUID(source.pages[0].chunkUUID || ""));
      try {
        const { data } = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    };
    
      const renderAnswer = (patientsSummary:any, sourceData:any) => {
        const extractTextRecursively = (node: any): string => {
            if (typeof node === "string") {
              return node;
            }
            if (Array.isArray(node)) {
              return node.map(extractTextRecursively).join("");
            }
            if (node?.props?.children) {
              return extractTextRecursively(node.props.children);
            }
            return "";
          }
          
          const getSourceNumberMap = (sourcesNew: any) => {
            const map: {
              [key: string]: {
                category: string;
                documentUUID:string;
                pages: Array<{
                  chunkUUID: string;
                  offsets: Array<{ begin: number; end: number }>;
                  pageNo: number;
                }>;
                sourceNumber: number;
              };
            } = {};
          
            sourcesNew?.forEach((source:any, index:number) => {
              map[source.id] = {
                category: source.file_name,
                documentUUID: source.document_uuid,
                pages:source.pages ? source.pages : [ // Use existing pages if available
                  {
                    chunkUUID: source.id,
                    offsets: [{ 
                      begin: source.begin || 0, 
                      end: source.end || 0 
                    }],
                    pageNo: source.page_num || 1
                  }
                ],
                sourceNumber: index + 1, 
              };
            });
          
            return map;
          };
          
          const RenderCitation: React.FC<{ children: React.ReactNode }> = ({ children }) => {
            const UUID_REGEX = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|[a-f0-9]{24}_\d+/g;
            const sourceNumberMap = getSourceNumberMap(sourceData);
          
            const currentLine = typeof children === "string" ? children : extractTextRecursively(children);
          
            const parts = currentLine.split(UUID_REGEX);
            const chunkUUIDs = currentLine.match(UUID_REGEX) || [];

            const processedParts = parts.map((part, index) => {
              const uuid = chunkUUIDs[index];
          
              const citation = uuid && sourceNumberMap[uuid] ? `${sourceNumberMap[uuid].sourceNumber}` : null;
             
              return (
                <React.Fragment key={index}>
                  <span>{part}</span>
                  {citation && (
                    <span
                      className="citation-number"
                      onClick={() =>
                        handleSourceClick(
                          sourceNumberMap[uuid]
                        )
                      }
                    >
                      {citation}
                    </span>
                  )}
                </React.Fragment>
              );
            });
          
            return <>{processedParts}</>;
          };
          
    
      const components = {
        li(props: any) {
          const { children } = props;
          return (
            <li>
              <RenderCitation>{children}</RenderCitation>
            </li>
          );
        },
        p(props: any) {
          const { children } = props;
          return (
            <p>
              <RenderCitation>{children}</RenderCitation>
            </p>
          );
        },
      };
    
      const reactElement = (
        <Markdown className="citation-markdown" components={components}>
          {patientsSummary}
        </Markdown>
      );
    
      return reactElement;
    };
    
  
    const renderSummary = (): ReactNode => {
        return (
            < >
            <h3>Summary</h3>
              <div className="display-auth-content">
              <span className="adjudicationSummary-title">Key Question: {' '}</span>
              {lastQuestion.split('ACTION REQUIRED')[0]?.trim()}
                <div className="">
                {patientSummary?.summary.text ? renderAnswer(patientSummary?.summary.text, patientSummary?.summary.sources) : patientSummary?.summary}
              </div>
                <span className="adjudicationSummary-title">Case Facts: {' '}</span>
                {renderAnswer(patientSummary.case_facts.text, patientSummary.case_facts.sources)}
              </div>
            </>

        );
      };
      
    const renderObservation = (): ReactNode => {
        return (
            <>
            <h3 className="heading-detailed-complience">Questionnaire Observation</h3>
            {patientSummary.validations?.map((item: any, index: number) => {
              return (
               item.sources.length > 0 && <div key={index} className="display-auth-content" style={{borderBottom: "1px solid lightgray",marginBottom: "10px",paddingBottom: "10px"}}>
                <div className="flex">
                  <span className="adjudicationSummary-title">Question:{' '}</span>
                  {item?.question}
                </div>
                <div className="">
                <span className="adjudicationSummary-title">Observation:{' '}</span>{renderAnswer(item?.observation, item?.sources)}
              </div>
              <div className="">
              <span className="adjudicationSummary-title">Validation Status:{' '}</span>
              {item?.validation}
            </div>
                </div>
              );
            })}
            </>
        );
      };

      const renderMedicalHistorySources = (): ReactNode => {
        return (
          <div className="source-container">
            <h5>Sources: </h5>
            <div className="source-list flex gp-10">
              {!!sources?.length &&
                sources?.map((source:any, i:any) => {
                  return (
                    <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                      <a>{source?.category}[1]</a>
                    </li>
                  );
                })}
            </div>
          </div>
        );
      };

      const exportMenu = (
        <Menu>
          <Menu.Item
            key="pdf"
            icon={<DownloadOutlined />}
            className="export-dropdown-item"
            onClick={() => handlePdfDownload(patientSummary, "Patient Summary", documentName)}
          >
            Download as PDF
          </Menu.Item>
          <Menu.Item
            key="docx"
            icon={<DownloadOutlined />}
            className="export-dropdown-item"
            onClick={() => handleDocxDownload(patientSummary, "Patient Summary", documentName)}
          >
            Download as Docx
          </Menu.Item>
          <Menu.Item
            key="copy"
            icon={<CopyOutlined />}
            className="export-dropdown-item"
            onClick={() => handleCopyToClipboard(patientSummary, "Patient Summary")}
          >
            Copy to Clipboard
          </Menu.Item>
        </Menu>
      );

  return (
    <div className="authorization-container">
      <div className="authorization-container-content">
        <section className="display-auth">
        {renderSummary()}
        {renderObservation()}
        {renderMedicalHistorySources()}
        {!!Object.keys(sources).length && (
        <SourcePopup
            chatSource={chatSource}
            setChatSource={setChatSource}
            fileUrl={fileUrl}
            guideline={projectDetails.guidelines}
        />
        )}
        </section>
        
      </div>
          <div className="patientData-footer" >
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
            </div>
            <div className="flex gp">
              <Dropdown overlay={exportMenu} placement="topCenter" trigger={["click"]}>
                <Button className="outline">Export Summary as</Button>
              </Dropdown>
              {/* <Button
                className="fill"
                onClick={handleGenerateCompliance}
              >
                Generate Guideline Compliance
              </Button> */}
            </div>
          </div>
    </div>
  );
};

export default Summary;

