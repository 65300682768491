import React, { useEffect, useState } from "react";
import "./prior-authorization.scss";
import { Button, Dropdown, Menu, message } from "antd";
import { priorAuthService } from "../../api";
import { handleZipFileDownload, MODAL_TYPE, openNotificationWithIcon } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { DiagnosisProps, ProcedureProps } from "../patient-summary";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DislikeOutlined,
  DownloadOutlined,
  EditOutlined,
  LikeOutlined,
  SaveFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { openModal, RootState, setFeedbackType, setSummaryId } from "../../store";
import { handlePdfDownload, handleDocxDownload, handleCopyToClipboard } from "../../utils";
import { Input } from "antd";
import { fileNamesWithResponseLetter } from "../response-letter/constants";
import { authorization, hardCodedPBMDocuments } from "../case-details-container/constants";

const { TextArea } = Input;

type DiagnosisType = {
  code?: string;
  description?: string;
};

type ProcedureType = {
  code?: string;
  description?: string;
};

export interface AdjudicationSummary {
  guideline_description: string;
  observation: string;
  compliance_status: "COMPLIANT" | "NON COMPLIANT";
}

export type EditPayloadType = {
  diagnoses: DiagnosisType;
  procedure: ProcedureType;
  overviewData: string;
  adjudicationSummaryData: AdjudicationSummary[];
};

type FormValuesType = {
  overviewData: string;
  adjudicationSummaryData: AdjudicationSummary[];
};

export const PriorAuthorization = ({
  pageData,
  projectId,
  diagnosisCode,
  procedureCode,
  selectedCheckboxes,
  fileName,
  enableNextTab,
}: Props) => {
  const [authContent, setAuthContent] = useState<string>("");
  const [activeTab, setActiveTab] = useState<"ams" | "qco">("ams");
  const [summary, setSummary] = useState({ ams: "", qco: "" });
  const [loading, setLoading] = useState({ summary: { ams: false, qco: false } });
  const [overview, setOverview] = useState("" as string);
  const [adjudicationSummary, setAdjudicationSummary] = useState([] as AdjudicationSummary[]);
  const [hardCodedjudicationSummary, setHardCodedAdjudicationSummary] = useState([] as string[]);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValuesType>({
    adjudicationSummaryData: [],
    overviewData: "",
  });
  const [isSavingData, setIsSavingData] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [recommendationSummary,setRecommendationSummary] = useState("")

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { guidelinesSummary, patientSummary } = useSelector(
    (state: RootState) => state.exportSummary
  );
  const { signedUrl} = useSelector(
    (state: RootState) => state.document
  );
  const guidelinesSummaryArray =guidelinesSummary.length>0 ? guidelinesSummary
    .map(
      (item: any) =>
        `Compliance Status: ${item.compliance_status}\n` +
        `Guideline Description: ${item.guideline_description}\n` +
        `Observation: ${item.observation}\n`
    )
    .join("") : "";
  const adjudicationSummaryArray=adjudicationSummary.length>0 ? adjudicationSummary
  .map(
    (item: any) =>
      `Compliance Status: ${item.compliance_status}\n` +
      `Guideline Description: ${item.guideline_description}\n` +
      `Observation: ${item.observation}\n`
  )
  .join("") : "";

  const getAuthorizationData = async () => {
    try {
      setIsSummaryLoading(true);
      const { data } = await priorAuthService.generateAuthorizationOverview(
        pageData.documentUUID,
        diagnosisCode,
        procedureCode,
        selectedCheckboxes
      );
      if (hardCodedPBMDocuments.includes(fileName)) {
        setOverview(authorization[fileName].overview);
        setHardCodedAdjudicationSummary(authorization[fileName].adjudication_summary);
        return;
      }

      setOverview(data.data.overview || "");

      const adjudicationSummaryData: AdjudicationSummary[] = (
        data.data.adjudication_summary || []
      ).map((item: any) => ({
        guideline_description: item.guideline_description || "",
        observation: item.observation || "",
        compliance_status: item.compliance_status,
      }));

      setAdjudicationSummary(adjudicationSummaryData);
      setFormValues({
        overviewData: data.data.overview || "",
        adjudicationSummaryData: adjudicationSummaryData,
      });
      dispatch(setSummaryId(data?.data?.summaryId));
      setIsSummaryLoading(false);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    getAuthorizationData();
  }, []);

  useEffect(()=>{
    if(hardCodedPBMDocuments.includes(fileName)){
      setRecommendationSummary(hardCodedjudicationSummary.join('\n'))
    }else{
      setRecommendationSummary(adjudicationSummaryArray)
    }
  },[hardCodedjudicationSummary,adjudicationSummaryArray])

  const handleInputChange = (key: string, value: string | string[], index?: number) => {
    if (key === "overviewData") {
      setFormValues((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "adjudicationSummaryData" && typeof index === "number") {
      const updatedAdjudicationSummary = [...formValues.adjudicationSummaryData];
      updatedAdjudicationSummary[index].observation = value as string;

      setFormValues((prev) => ({
        ...prev,
        adjudicationSummaryData: updatedAdjudicationSummary,
      }));
    }
  };

  const handleGenerateSummary = async () => {
    try {
      setLoading((prev) => ({ ...prev, summary: { ...prev.summary, [activeTab]: true } }));
      const payload = {
        q: "",
        metadataFilter: 0,
        containerId: projectId,
        qaId: String(crypto.randomUUID()),
        documentUuid: pageData.documentUUID,
        docVariant: "prior-auth",
      };

      if (activeTab === "ams") {
        payload.q = "Suggest adjacent medical services needed";
      } else {
        payload.q = '"Summarize quality care opportunities"';
      }

      const {
        data: {
          data: { answer },
        },
      } = await priorAuthService.getSummary(payload);
      setSummary((prev) => ({ ...prev, [activeTab]: answer }));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setLoading((prev) => ({ ...prev, summary: { ...prev.summary, [activeTab]: false } }));
    }
  };

  const getSubmitReview = async (status: string) => {
    try {
      await priorAuthService.submitReview({ status }, pageData.documentUUID);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleApprove = async () => {
    if(fileNamesWithResponseLetter.includes(fileName)){
      enableNextTab(selectedCheckboxes);
    } else {
      await getSubmitReview("APPROVED");
      navigate(-1);
    }
  };
  const handleReqForMoreInfo = async () => {
    await getSubmitReview("REQUESTED_MORE_INFO");
    navigate(-1);
  };
  const handleForwardForReview = async () => {
    await getSubmitReview("FORWARDED_FOR_REVIEW");
    navigate(-1);
  };
  const handleThumbsClick = (type: "up" | "down") => {
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  const handleCancelEdit = () => {
    setFormValues({
      overviewData: overview || "",
      adjudicationSummaryData: adjudicationSummary || [],
    });
    setIsEditing((prev) => !prev);
  };
  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleSave = async () => {
    try {
      setIsSavingData(true);
      const payload: EditPayloadType = {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisCode,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
        overviewData: formValues.overviewData,
        adjudicationSummaryData: formValues.adjudicationSummaryData,
      };
      const updatedData = await priorAuthService.editAuthorizationData(
        pageData.documentUUID,
        payload
      );
      setOverview(updatedData.data.overview || "");
      setAdjudicationSummary(updatedData.data.adjudication_summary || []);
      setFormValues({
        overviewData: updatedData.data.overview || "",
        adjudicationSummaryData: updatedData.data.adjudication_summary || [],
      });
      openNotificationWithIcon("Success", "Recommentation updated successfully", "success");
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsSavingData(false);
      setIsEditing(false);
    }
  };

  const extractObservation = (data: string): string | undefined => {
    const observations: any = adjudicationSummary.map((val, index) => {});
    return observations?.join("\n");
  };
  const exportMenu = () => {
    return(
    <Menu>
      <Menu.Item
        key="zip"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() =>
          handleZipFileDownload(
            [patientSummary, guidelinesSummaryArray, adjudicationSummaryArray],
            ["Patient Summary", "Guidelines Summary", "Recommendation Summary"],
            [pageData.name, pageData.name, pageData.name],
            signedUrl
          )
        }
      >
        Download as Zip File
      </Menu.Item>
      <Menu.Item
        key="pdf"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() =>
          handlePdfDownload(adjudicationSummaryArray, "Recommendation Summary", pageData.name)
        }
      >
        Download as PDF
      </Menu.Item>
      <Menu.Item
        key="docx"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() =>
          handleDocxDownload(adjudicationSummaryArray, "Recommendation Summary", pageData.name)
        }
      >
        Download as Docx
      </Menu.Item>
      <Menu.Item
        key="copy"
        icon={<CopyOutlined />}
        className="export-dropdown-item"
        onClick={() => handleCopyToClipboard(adjudicationSummaryArray, "Recommendation Summary")}
      >
        Copy to Clipboard
      </Menu.Item>
    </Menu>)
  };

  const renderEditButton = () => (
    <h3 className="authorize-edit-header">
      {!isEditing ?"Recommendation":<div></div>}
      {isEditing ? (
        <div className="flex gp">
          <Button size="middle" onClick={handleCancelEdit} className="outline">
            {" "}
            Cancel <CloseOutlined />
          </Button>
          <Button size="middle" loading={isSavingData} onClick={handleSave} className="fill">
            {" "}
            Save <CheckOutlined />
          </Button>
        </div>
      ) : (
        <Button size="middle" onClick={handleEditToggle} className="outline">
          Edit <EditOutlined />
        </Button>
      )}
    </h3>
  );
  const renderExportButtons = ()=>{
    return(
      <div className="actions flex">
          <Dropdown
            disabled={isEditing}
            overlay={exportMenu()}
            placement="topCenter"
            trigger={["click"]}
          >
            <Button disabled={isEditing} className="outline">
              Export Summary as
            </Button>
          </Dropdown>
         {fileNamesWithResponseLetter.includes(pageData.name) ? <Button disabled={isEditing} className="fill" onClick={handleApprove}>
            Draft Letter
          </Button> : <Button disabled={isEditing} className="fill" onClick={handleApprove}>
            Approved
          </Button>}
          <Button disabled={isEditing} className="fill" onClick={handleReqForMoreInfo}>
            Request More Info
          </Button>
          <Button disabled={isEditing} className="fill" onClick={handleForwardForReview}>
            Forward for review
          </Button>
        </div>
    )
  }
  const renderAuthorisationContent = () => {
    if(hardCodedPBMDocuments.includes(fileName)){
      return(
        <section className="display-auth">
        <h3>Recommendation</h3>
          <div className="display-auth-content">
            <div>{overview}</div>
            <h3>Detailed Compliance Report</h3>
            {hardCodedjudicationSummary.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
      </section>
      )
    }
    if (isSummaryLoading) return <Loading />;
    return (
      <section className="display-auth">
        {!isEditing  && renderEditButton()}
        {isEditing ? (
          <>
          <h3>Recommendation</h3>
          <TextArea
            rows={4}
            className="recomentation-edit-textArea"
            value={formValues.overviewData}
            onChange={(e) => handleInputChange("overviewData", e.target.value)}
          />
          </>
        ) : (
          <div className="display-auth-content">{overview}</div>
        )}
        <h3 className="heading-detailed-complience">Detailed Compliance Report</h3>
        {adjudicationSummary.map((item, index) => {
          return(
          <div key={index} className="display-auth-content">
            <div className="flex">
              <span className="adjudicationSummary-title">Guidelines Compliance:{' '}</span>

              {item.guideline_description}
            </div>
            {isEditing ? (
              <>
                <span className="adjudicationSummary-title">Observation:{' '}</span>
                <TextArea
                  rows={4}
                  className="recomentation-edit-textArea"
                  value={item.observation}
                  onChange={(e) =>
                    handleInputChange("adjudicationSummaryData", e.target.value, index)
                  }
                />
              </>
            ) : (
              <div className="">
                <span className="adjudicationSummary-title">Observation:{' '}</span> {item.observation}
              </div>
            )}
            <div className="">
              <span className="adjudicationSummary-title">Compliance Status:{' '}</span>
              {item.compliance_status ? "COMPLIANT" : "NON COMPLIANT"}
            </div>
          </div>)
        })}
      </section>
    );
  };

  return (
    <div className="authorization-container">
      <div className="authorization-container-content">
        {renderAuthorisationContent()}

        {/* <section className="summary-actions-container">
          <div className="actions">
            <span
              className={`actions-single ${activeTab === "ams" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("ams")}
            >
              Adjacent Medical Services
            </span>
            <span
              className={`actions-single ${activeTab === "qco" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("qco")}
            >
              Quality Care Opportunities
            </span>
          </div>
          <div className="generator-container white-box-bg">
            <div className="generator-content">
              <div>{summary[activeTab]}</div>
              {!summary[activeTab] && (
                <Button
                  className="outline"
                  onClick={handleGenerateSummary}
                  disabled={loading.summary[activeTab]}
                >
                  {loading.summary[activeTab] ? "Generating..." : "Generate"}
                </Button>
              )}
            </div>
          </div>
        </section> */}
      </div>
      <div className="case-actions">
        <div className="actions">
          {!isEditing && (
            <>
              <AskAuto projectId={projectId} documentUuid={pageData.documentUUID} />
              <LikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("up")}
              />
              <DislikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("down")}
              />
            </>
          )}
        </div>
        {isEditing ? (renderEditButton()):  (renderExportButtons())} 
      </div>
    </div>
  );
};

export default PriorAuthorization;

type Props = {
  pageData: {
    key: React.Key;
    fileFolder: string;
    createdOn: string;
    updatedOn: string;
    status: string;
    action: string;
    isFolder: boolean;
    name: string;
    documentUUID: string;
  };
  projectId: number;
  procedureCode?: ProcedureProps;
  diagnosisCode?: DiagnosisProps;
  selectedCheckboxes?: string[];
  fileName: string;
  enableNextTab: (selectedCheckboxes?: string[]) => void;
};
