import { IRepsonseWrapper, apiClient } from ".";
import { DiagnosisProps, ProcedureProps } from "../containers";

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  procedureDescription: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
  diagnosisDescription: string;
}

interface KeyValueOutput {
  acuity: string;
  admissionDateTime: string;
  authorizationID: string;
  dateOfBirth: string;
  dischargeDateTime: string;
  memberID: string;
  memberName: string;
  placeOfService: string;
  requestingProviderName: string;
  requestingProviderFaxNo: string;
  requestingProviderNPI: string;
  servicingProviderName: string;
  servicingProviderNPI: string;
  typeOfService: string;
  state: string;
  procedures: Procedure[];
  diagnoses: Diagnosis[];
  [key: string]: string | Procedure[] | Diagnosis[];
}

export interface DiagAndProcCodes {
  diagnosisCode: string;
  diagnosisDescription: string;
  procedureCode: string;
  procedureDescription: string;
}

interface Document {
  id: number;
  DocumentUUID: string;
  KeyValueOutput: KeyValueOutput;
  createdAt: string;
  updatedAt: string;
  diagAndProcCodes: DiagAndProcCodes[];
  extractedFieldCount: number;
  timeToProcess: number;
}

const getCaseInfo = async (documentUUID: string) => {
  const res = await apiClient.get<IRepsonseWrapper<Document>>(
    `/prior-auth/case-info/${documentUUID}`
  );
  return res;
};

const getQuestionnaire = async () => {
  const res = await apiClient.get(`/questionnaire`);
  return res;
};

const getQuestionnaireResponse = async (documentId:number) => {
  const res = await apiClient.get(`/questionnaire/response/${documentId}`);
  return res;
};

const updateQuestionnaireResponse = async (documentId:number,payload:any) => {
  const res = await apiClient.put(`/questionnaire/response/${documentId}`,{updateData:payload});
  return res;
};

const generatePatientSummary = async (
  documentUUID: string,
  diagnosisCode?: DiagnosisProps,
  procedureCode?: ProcedureProps,
  callFlag?: string
) => {
  const payload = callFlag
    ? {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisDescription,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
        call_flag: callFlag,
      }
    : {
        diagnoses: {
          code: diagnosisCode?.diagnosisCode,
          description: diagnosisCode?.diagnosisDescription,
        },
        procedure: {
          code: procedureCode?.procedureCode,
          description: procedureCode?.procedureDescription,
        },
      };
  const res = await apiClient.post(`/prior-auth/patient-summary/${documentUUID}`, payload);
  return res;
};

const getPatientSummary = async (
  documentUUID: string,
  diagnosisCode: DiagnosisProps,
  procedureCode: ProcedureProps
) => {
  const res = await apiClient.get(
    `/prior-auth/patient-summary/${documentUUID}?diagnosis_code=${diagnosisCode.diagnosisCode}&procedure_code=${procedureCode.procedureCode}`
  );
  return res;
};


const generateQuestionnaireSummary = async (
  documentUUID: string, payload:any
) => {
  const res = await apiClient.post(`/prior-auth/questionnaire-summary/${documentUUID}`, {summaryData : payload});
  return res;
};

export const patientSummaryService = {
  getCaseInfo,
  getPatientSummary,
  generatePatientSummary,
  getQuestionnaire,
  generateQuestionnaireSummary,
  getQuestionnaireResponse,
  updateQuestionnaireResponse
};
