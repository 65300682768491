import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IResponseType {
  answer: string;
  next_question: string;
}

interface IQuestionnaire {
  id: number;
  projectId: number;
  questionId: number;
  question: string;
  responseSelected: string;
  responseTypes: IResponseType[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface IQuestionnaireSliceInitialState {
  questionnaire: IQuestionnaire[];
  questionnaireByDocId: IQuestionnaire[];
}

export const questionnaireInitialState: IQuestionnaireSliceInitialState = {
  questionnaire: [],
  questionnaireByDocId:[]
};

export const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: questionnaireInitialState,
  reducers: {
    setQuestionnaire: (
      state,
      { payload }: PayloadAction<IQuestionnaire[]>
    ) => {
      state.questionnaire = payload;
    },
    setQuestionnaireByDocId: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.questionnaireByDocId = payload;
    },

    addQuestion: (state, { payload }: PayloadAction<IQuestionnaire>) => {
      state.questionnaire.push(payload);
    },

    removeQuestion: (state, { payload }: PayloadAction<number>) => {
      state.questionnaire = state.questionnaire.filter((q) => q.id !== payload);
    },
  },
});

export const {
  setQuestionnaire,
  addQuestion,
  removeQuestion,
  setQuestionnaireByDocId
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
